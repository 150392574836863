import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Modal from "../../../../Shared/Modal/Modal";
import InitWidgetScript from "./Functions/InitWidgetScript";
import DisplayMockedSessionCreatedModal from "./Functions/DisplayMockedSessionCreatedModal";
import GetState from "./Functions/GetState";
import SetPosition from "./SetPosition/SetPosition";
import SetSize from "./SetSize/SetSize";
import SetMaximized from "./SetMaximized/SetMaximized";
import SetSection from "./SetSection/SetSection";
import SetCshId from "./SetCshId/SetCshId";
import SetQuery from "./SetQuery/SetQuery";

const DevTool = () => {
  const [state, setState] = useState<
    | {
        integrationDetails: {
          ApplicationCode: string;
          Country: string;
          Language: string;
          SessionProviderId: string;
          Source: string;
          SubmitButtonValue: string;
          Variant: string;
        };
        mockedSessionCreated: boolean;
      }
    | undefined
  >();
  const [successModal, setSuccessModal] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    const integrationId = location.search.split("=")[1];
    if (!state) {
      GetState(location, setState, integrationId);
    } else {
      InitWidgetScript(state, integrationId);
      DisplayMockedSessionCreatedModal(
        location,
        setSuccessModal,
        integrationId
      );
    }
  }, [state, location]);

  return (
    <div className="DevTool">
      <div className="panel border rounded">
        <div className="panel-body">
          <h1 className="no-margin-bottom text-center">Dev Tool</h1>
        </div>
        <div className="panel-footer">
          <div
            className="hcOpener margin-right"
            style={{
              position: "relative",
            }}>
            <input
              type="button"
              value="Open support panel"
              id="btnOpen"
              className="btn btn-small btn-primary no-margin-bottom"
              onClick={() => window.supportPanelWidget?.open()}></input>
            <span
              className="helpwidget-unread-panel-notifications"
              style={{
                display: "none",
                position: "absolute",
                top: "-7px",
              }}></span>
          </div>
          <div className="publicFunctions">
            <h3 className="no-margin-bottom margin-top">
              <strong>Public functions:</strong>
            </h3>
            <div
              className="alert alert-info alert-sm no-margin-bottom"
              role="alert"
              style={{ width: "fit-content" }}>
              <strong>Documentation </strong>for public functions is{" "}
              <a
                href="https://confluence.visma.com/display/VHC/Supportpanelwidget.js+-+Public+functions"
                target="_blank"
                rel="noopener noreferrer">
                here
              </a>
              .
            </div>
            <hr />
            <SetPosition />
            <hr />
            <SetSize />
            <hr />
            <SetMaximized />
            <hr />
            <SetSection />
            <hr />
            <SetCshId />
            <hr />
            <SetQuery />
          </div>
        </div>
      </div>
      {successModal && (
        <Modal
          message={successModal}
          title="Mock session has been created."
          type="modal-success"
          toggleModal={(message: string) => setSuccessModal(message)}
        />
      )}
    </div>
  );
};

export default DevTool;
