import { FormTemplateItems } from "../../models/FormTemplate";

const SetByIntegrationIdFormTemplate = (
  defaultValue: string,
  title: string,
  buttonName: string
): FormTemplateItems => {
  return [
    {
      title,
      elements: [
        {
          type: "text",
          label: "IntegrationId*",
          value: defaultValue,
          required: true,
        },
      ],
      submit: [
        {
          name: buttonName,
          value: "GetIntegration",
          type: "submit",
        },
      ],
    },
  ];
};

export default SetByIntegrationIdFormTemplate;
