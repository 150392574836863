import React from "react";
import "./App.scss";
import Menu from "./components/Main/Menu/Menu";
import LoadingSpinner from "./components/Main/LoadingSpinner/LoadingSpinner";
import Modal from "./components/Shared/Modal/Modal";
import useAxiosLoader from "./hooks/useAxiosLoader";
import useAuthenticationContext from "./hooks/useAuthenticationContext";
import Login from "./components/Main/Login/Login";

function App() {
  const [loading, errorMessage, setErrorMessage] = useAxiosLoader();
  const [userContext, setUserContext] = useAuthenticationContext();

  return (
    <div className="App">
      {userContext.clientPrincipal ? (
        <>
          <Menu
            setUserContextHandler={setUserContext}
            userContext={userContext}
          />
          {errorMessage && (
            <Modal
              message={errorMessage}
              title="An unexpected error has occurred while processing your request."
              type="modal-error"
              toggleModal={(message: string) => setErrorMessage(message)}
            />
          )}
          {loading && <LoadingSpinner />}
        </>
      ) : (
        <Login setUserContextHandler={setUserContext} />
      )}
    </div>
  );
}

export default App;
