import React, { useEffect, useState } from "react";
import { FormTemplateItems } from "../../../../../models/FormTemplate";
import { SeveraMockedSessionModel } from "../../../../../models/MockedSession/SeveraMockedSession";
import { SpcsOnPremMockedSessionModel } from "../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { StandaloneMockedSessionModel } from "../../../../../models/MockedSession/StandaloneMockedSession";
import { VismaNetMockedSessionModel } from "../../../../../models/MockedSession/VismaNetMockedSession";
import { VismaOnlineMockedSessionModel } from "../../../../../models/MockedSession/VismaOnlineMockedSession";
import { UniqueIdGenerator } from "../../../../Shared/CommonFunctions";
import FetchMockSession from "../../../../Shared/FetchMockSession";
import FormTemplate from "../../../../Shared/FormTemplate/FormTemplate";
import Modal from "../../../../Shared/Modal/Modal";
import SetByIntegrationIdFormTemplate from "../../../../Shared/SetByIntegrationIdFormTemplate";
import RemoveMockSession from "./Functions/RemoveMockSession";
import { JwtMockedSessionModel } from "../../../../../models/MockedSession/JwtMockedSession";

const DeleteMockSession = () => {
  const [
    byIntegrationIdFormData,
    setByIntegrationIdFormData,
  ] = useState<FormTemplateItems>(
    SetByIntegrationIdFormTemplate(
      "",
      "Delete mock session for integrationId",
      "Delete mock session"
    )
  );
  const [mockedSession, setMockedSession] = useState<
    | VismaOnlineMockedSessionModel
    | SpcsOnPremMockedSessionModel
    | VismaNetMockedSessionModel
    | StandaloneMockedSessionModel
    | SeveraMockedSessionModel
    | JwtMockedSessionModel
  >();
  const [integrationId, setIntegrationId] = useState<string>();
  const [successModal, setSuccessModal] = useState<string>();

  useEffect(() => {
    if (mockedSession && integrationId) {
      RemoveMockSession(mockedSession, integrationId, setSuccessModal);
    }
  }, [mockedSession, integrationId]);

  return (
    <div className="DeleteMockSession">
      <div className="panel border rounded">
        <div className="panel-body">
          <h1 className="no-margin-bottom text-center">Delete Mock Session</h1>
        </div>
        <div className="panel-footer">
          {byIntegrationIdFormData.map((formData) => {
            return (
              <FormTemplate
                key={UniqueIdGenerator()}
                template={formData}
                handleSubmitEvent={(data) => {
                  setIntegrationId(data.IntegrationId);
                  setByIntegrationIdFormData(
                    SetByIntegrationIdFormTemplate(
                      data.IntegrationId,
                      "Delete mock session for integrationId",
                      "Delete mock session"
                    )
                  );
                  FetchMockSession(data, setMockedSession);
                }}
              />
            );
          })}
          {successModal && (
            <Modal
              message={successModal}
              title="Mock session was successfully deleted."
              type="modal-success"
              toggleModal={(message: string) => setSuccessModal(message)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteMockSession;
