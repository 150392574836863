import React from "react";
import { FormTemplateElement } from "../../../../models/FormTemplate";
import { UniqueIdGenerator } from "../../CommonFunctions";

const FormSelect = (props: {
  data: FormTemplateElement;
  register: Function;
}) => {
  return (
    <div className="FormSelect">
      <select className="form-control" {...props.register()}>
        {Array.isArray(props.data.value) &&
          props.data.value.map((val) => (
            <option key={UniqueIdGenerator()}>{val}</option>
          ))}
      </select>
    </div>
  );
};

export default FormSelect;
