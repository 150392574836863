import React, { FormEvent } from "react";

const SetPosition = () => {
  return (
    <div className="SetPosition">
      <form
        className="form-horizontal col-md-8"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          window.supportPanelWidget?.open();
          window.supportPanelWidget?.setPosition(
            e.target[0].value,
            e.target[1].value
          );
        }}>
        <div className="form-group">
          <label
            htmlFor="inputTop"
            className="col-sm-2 control-label text-center">
            Top:
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              name="top"
              className="form-control"
              id="inputTop"
              defaultValue="10"
            />
          </div>
        </div>
        <div className="form-group">
          <label
            htmlFor="inputLeft"
            className="col-sm-2 control-label text-center">
            Left:
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              name="left"
              className="form-control"
              id="inputLeft"
              defaultValue="10"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-10 offset-sm-2">
            <button type="submit" className="btn btn-default right">
              SetPosition
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SetPosition;
