import React, { FormEvent } from "react";

const SetQuery = () => {
  return (
    <div className="SetQuery">
      <form
        className="form-horizontal col-md-8"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          window.supportPanelWidget?.open();
          window.supportPanelWidget?.setSearchQuery(e.target[0].value);
        }}>
        <div className="form-group">
          <label htmlFor="query" className="col-sm-2 control-label text-center">
            Query:
          </label>
          <div className="col-sm-10">
            <input type="text" list="query" />
            <datalist id="query">
              <option value="visma">visma</option>
              <option value="video">video</option>
              <option value="help">help</option>
            </datalist>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-10 offset-sm-2">
            <button type="submit" className="btn btn-default right">
              SetQuery
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SetQuery;
