import isProd from "./GetHcEnv/IsProductionEnv";
import isStage from "./GetHcEnv/IsStageEnv";

const GetHcRootUrl = () => {
  switch (true) {
    case isProd:
      return "https://helpcentre.visma.net/";
    case isStage:
      return "https://stage.helpcentre.visma.net/";
    default:
      //EDIT THIS ONE FOR LOCAL HOST
      return "https://test.helpcentre.visma.net/";
  }
};
export default GetHcRootUrl;
