import React from "react";
import { FormTemplateSubmit } from "../../../../models/FormTemplate";
import { UniqueIdGenerator } from "../../CommonFunctions";

const FormSubmit = (props: {
  data: Array<FormTemplateSubmit>;
  onClick: Function;
}) => {
  return (
    <div className="FormSubmit">
      {props.data.map((submitButton, index) => {
        return (
          <React.Fragment key={UniqueIdGenerator()}>
            {submitButton && (
              <button
                className={`btn margin-top ${
                  index === 0 && "btn-primary margin-right"
                }`}
                type={submitButton.type}
                value={submitButton.value}
                onClick={() => props.onClick(submitButton.value)}>
                {submitButton.name}
              </button>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FormSubmit;
