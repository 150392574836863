import { httpRequest } from "../../../../../../hooks/useAxiosLoader";
import { JwtMockedSessionModel } from "../../../../../../models/MockedSession/JwtMockedSession";
import { SeveraMockedSessionModel } from "../../../../../../models/MockedSession/SeveraMockedSession";
import { SpcsOnPremMockedSessionModel } from "../../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { StandaloneMockedSessionModel } from "../../../../../../models/MockedSession/StandaloneMockedSession";
import { VismaNetMockedSessionModel } from "../../../../../../models/MockedSession/VismaNetMockedSession";
import { VismaOnlineMockedSessionModel } from "../../../../../../models/MockedSession/VismaOnlineMockedSession";

const RemoveMockSession = async (
  mockedSession:
    | VismaOnlineMockedSessionModel
    | SpcsOnPremMockedSessionModel
    | VismaNetMockedSessionModel
    | StandaloneMockedSessionModel
    | SeveraMockedSessionModel
    |JwtMockedSessionModel,
  integrationId: string,
  setSuccessMessage: React.Dispatch<string | undefined>
) => {
  const request = await httpRequest.delete(
    `./api/DeleteMockSession?mockedSessionId=${integrationId}&mockedSessionProvider=${mockedSession.SessionProviderId}`
  );
  if (request.status === 200) {
    setSuccessMessage(
      `Mock session for integrationId ${integrationId} was updated.`
    );
  }
};
export default RemoveMockSession;
