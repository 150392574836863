import React, { FormEvent } from "react";

const SetSection = () => {
  return (
    <div className="SetSection">
      <form
        className="form-horizontal col-md-8"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          window.supportPanelWidget?.open();
          window.supportPanelWidget?.setSection(
            e.target[0].value,
            e.target[1].value
          );
        }}>
        <div className="form-group">
          <label
            htmlFor="section"
            className="col-sm-2 control-label text-center">
            Section:
          </label>
          <div className="col-sm-10">
            <select id="section">
              <option value="gettingstarted">gettingstarted</option>
              <option value="onlinehelp">onlinehelp</option>
              <option value="faq">faq</option>
              <option value="supportticketlist">supportticketlist</option>
              <option value="contact">contact</option>
              <option value="movie">movie</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="Data" className="col-sm-2 control-label text-center">
            Data:
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="left"
              className="form-control"
              id="Data"
              placeholder="Only if section is 'movie'"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-10 offset-sm-2">
            <button type="submit" className="btn btn-default right">
              SetSection
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SetSection;
