import { httpRequest } from "../../hooks/useAxiosLoader";
import { IntegrationDetailsModel } from "../../models/IntegrationDetails";
import { JwtMockedSessionModel } from "../../models/MockedSession/JwtMockedSession";
import { SeveraMockedSessionModel } from "../../models/MockedSession/SeveraMockedSession";
import { SpcsOnPremMockedSessionModel } from "../../models/MockedSession/SpcsOnPremMockedSession";
import { StandaloneMockedSessionModel } from "../../models/MockedSession/StandaloneMockedSession";
import { VismaNetMockedSessionModel } from "../../models/MockedSession/VismaNetMockedSession";
import { VismaOnlineMockedSessionModel } from "../../models/MockedSession/VismaOnlineMockedSession";

const FetchMockSession = async (
  data: { IntegrationId: string },
  setMockSession?: React.Dispatch<
    React.SetStateAction<
      | VismaOnlineMockedSessionModel
      | SpcsOnPremMockedSessionModel
      | VismaNetMockedSessionModel
      | StandaloneMockedSessionModel
      | SeveraMockedSessionModel
      | JwtMockedSessionModel
      | undefined
    >
  >,
  createMockSession?: boolean,
  integrationDetailsValue?: IntegrationDetailsModel
) => {
  try {
    const integrationDetails: IntegrationDetailsModel = integrationDetailsValue
      ? integrationDetailsValue
      : (
          await httpRequest.get(
            `./api/GetIntegrationDetails?integrationId=${data.IntegrationId}`
          )
        ).data;
    const mockedSession:
      | VismaOnlineMockedSessionModel
      | SpcsOnPremMockedSessionModel
      | VismaNetMockedSessionModel
      | StandaloneMockedSessionModel
      | SeveraMockedSessionModel
      | JwtMockedSessionModel = (createMockSession
      ? await httpRequest.get(
          `./api/ReadMockSession?mockedSessionId=${
            data.IntegrationId
          }&mockedSessionProvider=${integrationDetails.SessionProviderId.toLocaleLowerCase()}&createMockSession=true`
        )
      : await httpRequest.get(
          `./api/ReadMockSession?mockedSessionId=${
            data.IntegrationId
          }&mockedSessionProvider=${integrationDetails.SessionProviderId.toLocaleLowerCase()}`
        )
    ).data;
    if (mockedSession && setMockSession) {
      setMockSession(mockedSession);
    }
    return {
      mockedSession,
      integrationDetails,
    };
  } catch (error) {}
};

export default FetchMockSession;
