import React from "react";
import FormCheckBox from "../FormCheckBox/FormCheckBox";
import FormInput from "../FormInput/FormInput";
import FormSelect from "../FormSelect/FormSelect";

const FormComputed = (props: {
  computedElement: {
    label: string;
    type: string;
  };
  register: Function;
  parentPropertyName: string;
}) => {
  return (
    <div className="FormComputed">
      <div className="form-group no-margin-bottom">
        <label
          htmlFor={props.computedElement.label}
          style={{
            minWidth: "80px",
            marginBottom: "10px",
            fontWeight: "bold",
          }}>
          {props.computedElement.label} :
        </label>
        <div className="col-sm-8">
          {(props.computedElement.type === "text" ||
            props.computedElement.type === "number") && (
            <FormInput
              data={props.computedElement}
              register={props.register}
              type={props.computedElement.type}
              parentPropertyName={props.parentPropertyName}
            />
          )}
          {props.computedElement.type === "select" && (
            <FormSelect
              data={props.computedElement}
              register={props.register}
            />
          )}
          {props.computedElement.type === "checkbox" && (
            <FormCheckBox
              data={props.computedElement}
              register={props.register}
              parentPropertyName={props.parentPropertyName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormComputed;
