import { httpRequest } from "../../../../../../hooks/useAxiosLoader";
import { IntegrationDetailsModel } from "../../../../../../models/IntegrationDetails";
import { CurrentIntegration } from "../../../../../../models/MockedSession/CurrentIntegration";
import { StandaloneMockedSessionModel } from "../../../../../../models/MockedSession/StandaloneMockedSession";
import { UniqueIdGenerator } from "../../../../../Shared/CommonFunctions";

const CreateMockSessionForStandalone = async (
  integrationDetails: IntegrationDetailsModel,
  integrationId: string,
  setSuccessModal?: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  const currentIntegration: CurrentIntegration = {
    ApplicationCode: integrationDetails.ApplicationCode,
    PlatformName: integrationDetails.Source,
    CountryCode: integrationDetails.CountryCode,
    LanguageCode: integrationDetails.LanguageCode,
    DistributorName: "None",
    IntegrationId: integrationId,
    Variant: integrationDetails.Variant,
    OnlineApplicationId: UniqueIdGenerator(),
    ApplicationName: integrationDetails.ApplicationCode,
    HasTickets: 1,
    InvariantKey: "",
    ApplicationTypeName: "",
    CustomerNumber: 123,
  };
  let mockedSession: StandaloneMockedSessionModel = {
    IsAuthorized: true,
    Services: [currentIntegration],
    IsAuthenticated: true,
    CurrentIntegration: currentIntegration,
    ParentApplication: {
      Platform: 0,
      ApplicationCode: "",
      CountryCode: "",
      LanguageCode: "",
      DistributorName: "",
      VariantName: "",
      Version: "",
      IntegrationId: integrationId,
      RedirectUri: "",
      VismaConnectScope: "",
    },
    Email: "mwoszczyk@vline.spcs.se",
    Firstname: "Mateus",
    Lastname: "W",
    SessionProviderId: integrationDetails.SessionProviderId.toLocaleLowerCase(),
    LocalUserIdentifier: UniqueIdGenerator(),
    MobileNumber: "",
    UserHasReadOnlyAccess: true,
    UserHasAccessToTickets: true,
    UserIdentifiers: {
      StandaloneUserId: UniqueIdGenerator(),
    },
  };
  const request = await httpRequest.post("./api/CreateMockSession", {
    mockedSession: JSON.stringify(mockedSession),
    mockedSessionId: integrationId,
    mockedSessionProvider: mockedSession.SessionProviderId.toLocaleLowerCase(),
  });
  if (request.status === 200 && setSuccessModal) {
    setSuccessModal(
      `Mock session for integrationId ${mockedSession.CurrentIntegration.IntegrationId} was created.`
    );
  }
};

export default CreateMockSessionForStandalone;
