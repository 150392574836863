import { FormTemplateItems } from "../../../../../../models/FormTemplate";
import { JwtMockedSessionModel } from "../../../../../../models/MockedSession/JwtMockedSession";
import { SeveraMockedSessionModel } from "../../../../../../models/MockedSession/SeveraMockedSession";
import { SpcsOnPremMockedSessionModel } from "../../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { StandaloneMockedSessionModel } from "../../../../../../models/MockedSession/StandaloneMockedSession";
import { VismaNetMockedSessionModel } from "../../../../../../models/MockedSession/VismaNetMockedSession";
import { VismaOnlineMockedSessionModel } from "../../../../../../models/MockedSession/VismaOnlineMockedSession";

const CreateFormTemplateFromJson = (
  formTitle: string,
  jsonObj:
    | VismaOnlineMockedSessionModel
    | SpcsOnPremMockedSessionModel
    | VismaNetMockedSessionModel
    | StandaloneMockedSessionModel
    | SeveraMockedSessionModel
    | JwtMockedSessionModel
) => {
  let formTemplate: FormTemplateItems = [
    {
      title: formTitle,
      elements: [],
      submit: [
        {
          name: "Save mock session",
          value: "SaveMockSession",
          type: "submit",
        },
      ],
    },
  ];

  const jsonWithoutNullValue = JSON.parse(
    JSON.stringify(jsonObj).replace(/null/g, '""')
  );

  for (const property in jsonWithoutNullValue) {
    switch (true) {
      case typeof jsonWithoutNullValue[property] === "string": {
        formTemplate[0].elements.push({
          type: "text",
          label: property,
          value: jsonWithoutNullValue[property],
          placeholder: `Enter ${property}`,
        });
        break;
      }
      case typeof jsonWithoutNullValue[property] === "boolean": {
        formTemplate[0].elements.push({
          type: "checkbox",
          label: property,
          value: jsonWithoutNullValue[property],
          placeholder: `Enter ${property}`,
        });
        break;
      }
      case typeof jsonWithoutNullValue[property] === "number": {
        formTemplate[0].elements.push({
          type: "number",
          label: property,
          value: jsonWithoutNullValue[property],
          placeholder: `Enter ${property}`,
        });
        break;
      }
      // JSON objectc case
      case typeof jsonWithoutNullValue[property] === "object" &&
        !Array.isArray(jsonWithoutNullValue[property]) &&
        jsonWithoutNullValue[property] !== null: {
        formTemplate[0].elements.push({
          type: "computed",
          label: property,
          computedElements: [],
        });
        for (const secondLayerProperty in jsonWithoutNullValue[property]) {
          SetSecondLayerPropertyType(
            jsonWithoutNullValue,
            property,
            secondLayerProperty,
            formTemplate
          );
        }
        break;
      }
    }
  }

  return formTemplate;
};

function SetSecondLayerPropertyType(
  jsonWithoutNullValue: any,
  property: string,
  secondLayerProperty: string,
  formTemplate: FormTemplateItems
) {
  switch (true) {
    case typeof jsonWithoutNullValue[property][secondLayerProperty] ===
      "string": {
      formTemplate[0].elements[
        formTemplate[0].elements.length - 1
      ]?.computedElements?.push({
        type: "text",
        label: secondLayerProperty,
        value: jsonWithoutNullValue[property][secondLayerProperty],
        placeholder: `Enter ${secondLayerProperty}`,
      });
      break;
    }
    case typeof jsonWithoutNullValue[property][secondLayerProperty] ===
      "number": {
      formTemplate[0].elements[
        formTemplate[0].elements.length - 1
      ]?.computedElements?.push({
        type: "number",
        label: secondLayerProperty,
        value: jsonWithoutNullValue[property][secondLayerProperty],
        placeholder: `Enter ${secondLayerProperty}`,
      });
      break;
    }
    case typeof jsonWithoutNullValue[property][secondLayerProperty] ===
      "boolean": {
      formTemplate[0].elements[
        formTemplate[0].elements.length - 1
      ]?.computedElements?.push({
        type: "boolean",
        label: secondLayerProperty,
        value: jsonWithoutNullValue[property][secondLayerProperty],
        placeholder: `Enter ${secondLayerProperty}`,
      });
      break;
    }
    default:
      break;
  }
}

export default CreateFormTemplateFromJson;
