import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import LocalhostAuthenticatedUserContext from "../components/Shared/LocalhostAuthenticatedUserContext";
import { AuthenticationContextModel } from "../models/AuthenticationContext";
import { httpRequest } from "./useAxiosLoader";

const useAuthenticationContext = (): [
  AuthenticationContextModel,
  React.Dispatch<React.SetStateAction<AuthenticationContextModel>>
] => {
  const [authenticationContext, setAuthenticationContext] = useState<
    AuthenticationContextModel
  >({ clientPrincipal: null });
  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      httpRequest
        .get("./.auth/me")
        .then((response: AxiosResponse<AuthenticationContextModel>) => {
          setAuthenticationContext(response.data);
        });
    } else {
      const mockedContext: AuthenticationContextModel = LocalhostAuthenticatedUserContext;
      setAuthenticationContext(mockedContext);
    }
  }, []);

  return [authenticationContext, setAuthenticationContext];
};

export default useAuthenticationContext;
