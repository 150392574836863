import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";

//use this in all components
export const httpRequest = axios.create();

const useAxiosLoader = (): [
  boolean,
  string,
  React.Dispatch<React.SetStateAction<string>>
] => {
  const [counter, setCounter] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const inc = useCallback(
    () => setCounter((counter) => counter + 1),
    [setCounter]
  ); // add to counter
  const dec = useCallback(
    () => setCounter((counter) => counter - 1),
    [setCounter]
  ); // remove from counter

  // add request prefix for localhost
  httpRequest.defaults.baseURL =
    window.location.hostname === "localhost" ? "http://localhost:7071/" : "";

  // create the interceptors
  const interceptors = useMemo(
    () => ({
      request: (config: InternalAxiosRequestConfig) => {
        inc();
        return config;
      },
      response: (response: AxiosResponse) => {
        dec();
        return response;
      },
      error: (error: AxiosError) => {
        dec();
        if (error.message) {
          setErrorMessage(error.message);
        }
        return Promise.reject(error);
      },
    }),
    [inc, dec]
  );

  useEffect(() => {
    // add request interceptors
    httpRequest.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    httpRequest.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      // remove all intercepts when done
      httpRequest.interceptors.request.eject(Number(interceptors.request));
      httpRequest.interceptors.request.eject(Number(interceptors.error));
      httpRequest.interceptors.response.eject(Number(interceptors.response));
      httpRequest.interceptors.response.eject(Number(interceptors.error));
    };
  }, [interceptors]);

  return [counter > 0, errorMessage, setErrorMessage];
};

export default useAxiosLoader;
