import React from "react";
import loadingSpinnerSvg from "@vismaux/nordic-cool/dist/img/spinners/spinner_doughnut_blue_light.svg";
import "./LoadingSpinner.scss";

const LoadingSpinner = () => {
  return (
    <div className="LoadingSpinner">
      <object
        type="image/svg+xml"
        data={loadingSpinnerSvg}
        aria-label="loadingSpinner"></object>
    </div>
  );
};

export default LoadingSpinner;
