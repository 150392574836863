import React from "react";
import { FormTemplateElement } from "../../../../models/FormTemplate";

const FormCheckBox = (props: {
  data: FormTemplateElement;
  register: Function;
  parentPropertyName?: string;
}) => {
  const fieldName = props.parentPropertyName
    ? `${props.parentPropertyName}-${props.data.label}`
    : props.data?.label.includes("*")
    ? props.data.label.slice(0, -1)
    : props.data.label;

  return (
    <div className="FormCheckBox">
      <div className="checkbox d-block">
        <input
          type="checkbox"
          id={props.data.label}
          {...props.register(fieldName, { required: props.data.required })}
          defaultChecked={Boolean(props.data.value)}
        />
        <label htmlFor={props.data.label}>
          {props.data.label.includes("*")
            ? props.data.label.slice(0, -1)
            : props.data.label}
        </label>
      </div>
    </div>
  );
};

export default FormCheckBox;
