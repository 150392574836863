import React from "react";

const Modal = (props: {
  title: string;
  message: string;
  toggleModal: Function;
  type: string;
}) => {
  return (
    <div
      id="modal"
      style={{ zIndex: 99999, minWidth: 320 }}
      className={`modal ${props.type} fade in example-modal`}
      tabIndex={1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-toggle="modal"
              data-target="#modal"
              aria-label="Close"
              onClick={() => props.toggleModal("")}></button>
            <h4 id="modal-title" className="modal-title">
              {props.title}
            </h4>
            <hr />
          </div>
          <div className="modal-body">
            <p>{props.message}</p>
            <hr />
          </div>
          <div className="modal-footer d-flex flex-row justify-content-between">
            <button
              type="button"
              style={{ display: "block", margin: "0 auto" }}
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#modal"
              onClick={() => props.toggleModal("")}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
