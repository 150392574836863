import React from "react";
import { AuthenticationContextModel } from "../../../models/AuthenticationContext";
import LocalhostAuthenticatedUserContext from "../../Shared/LocalhostAuthenticatedUserContext";
import "./Login.scss";

const Login = (props: {
  setUserContextHandler: (context: AuthenticationContextModel) => void;
}) => {
  const LogIn = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (window.location.hostname === "localhost") {
      props.setUserContextHandler(LocalhostAuthenticatedUserContext);
    }
  };

  return (
    <div className="Login">
      <div className="panel border rounded-20 allign-center">
        <div className="panel-body text-center">
          {window.location.hostname === "localhost" ? (
            <a
              href="#/"
              onClick={(event: React.MouseEvent<HTMLElement>) => LogIn(event)}>
              <span className="vismaicon vismaicon-user-placeholder margin-right-sm"></span>
              Log in with localhost mocked user.
            </a>
          ) : (
            <a href="/.auth/login/google">
              <span
                aria-hidden="true"
                className="vismaicon vismaicon-google margin-right-sm"></span>
              Log in with your Goggle account
            </a>
          )}
        </div>
        <div className="panel-footer text-center">
          <div className="align-items-center">
            <p className="justify-content-center">
              <span
                aria-hidden="true"
                className="vismaicon vismaicon-filled vismaicon-error margin-right-sm"></span>
              Your are not logged in. Acces to this application is allowed only
              for authenticated users.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
