import React from "react";
import { AuthenticationContextModel } from "../../../models/AuthenticationContext";
import LogOut from "./Functions/LogOut";
import ToggleVerticalMenu from "./Functions/ToggleVerticalMenu";
import "./Menu.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Routing from "./Routing/Routing";

const Menu = (props: {
  setUserContextHandler: (context: AuthenticationContextModel) => void;
  userContext: AuthenticationContextModel;
}) => {
  return (
    <div className="Menu">
      <Router>
        <div className="page-holder hidden-vert-menu">
          <div className="vertical-nav hidden-menu">
            <h1
              className="text-center"
              title={props.userContext.clientPrincipal?.userDetails}
              style={{ color: "black" }}>
              <span className="vismaicon vismaicon-user-placeholder margin-right-sm"></span>
              {props.userContext.clientPrincipal?.userDetails.split("@")[0]}
            </h1>
            <hr />
            <ul className="nav">
              <li>
                <Link to="/">Dev Page</Link>
              </li>
              <li>
                <Link to="/createMockSession">Create Mock Session</Link>
              </li>
              <li>
                <Link to="/updateMockSession">Update Mock Session</Link>
              </li>
              <li>
                <Link to="/deleteMockSession">Delete Mock Session</Link>
              </li>
              {window.location.hostname === "localhost" ? (
                <li
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    LogOut(event, props.setUserContextHandler)
                  }>
                  <a href="#/">Log Out</a>
                </li>
              ) : (
                <li>
                  <a href="/.auth/logout">Log Out</a>
                </li>
              )}
              <li
                className="show-nav"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  ToggleVerticalMenu(event)
                }>
                <a href="#/">Show/Hide Menu</a>
              </li>
            </ul>
          </div>
          <div className="container no-padding">
            <div className="row">
              <div className="col-12 margin-top">
                <Routing />
              </div>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default Menu;
