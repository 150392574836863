import { IntegrationDetailsModel } from "../../../../../../../models/IntegrationDetails";
import GetHcRootUrl from "../../../../../../Shared/GetHcRootUrl";

const GetHcUrl = (
  integrationDetails: IntegrationDetailsModel,
  integrationId: string
): string => {
  const hcIntegrationParams = `integrationId=${integrationId}&mockedSessionId=${integrationId}&mockedSessionProvider=${integrationDetails.SessionProviderId}&synthetic=true`;
  return `${GetHcRootUrl()}?${hcIntegrationParams}`;
};

export default GetHcUrl;
