import React from "react";
import { FormTemplateElement } from "../../../../models/FormTemplate";

const FormInput = (props: {
  data: FormTemplateElement;
  register: Function;
  type: string;
  parentPropertyName?: string;
}) => {
  const fieldName = props.parentPropertyName
    ? `${props.parentPropertyName}-${props.data.label}`
    : props.data?.label.includes("*")
    ? props.data.label.slice(0, -1)
    : props.data.label;

  return (
    <div className="FormInput">
      <input
        type={props.type}
        placeholder={props.data.placeholder}
        className="form-control"
        {...props.register(fieldName, { required: props.data.required })}
        defaultValue={props.data.value?.toLocaleString()}
        readOnly={props.data.readonly}
        style={{ fontStyle: "italic" }}
      />
    </div>
  );
};

export default FormInput;
