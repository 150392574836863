import { IntegrationDetailsModel } from "../../../../../../models/IntegrationDetails";
import { JwtMockedSessionModel } from "../../../../../../models/MockedSession/JwtMockedSession";
import { SessionProviderMappingModel } from "../../../../../../models/MockedSession/SessionProviderMapping";
import { SeveraMockedSessionModel } from "../../../../../../models/MockedSession/SeveraMockedSession";
import { SpcsOnPremMockedSessionModel } from "../../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { StandaloneMockedSessionModel } from "../../../../../../models/MockedSession/StandaloneMockedSession";
import { VismaNetMockedSessionModel } from "../../../../../../models/MockedSession/VismaNetMockedSession";
import { VismaOnlineMockedSessionModel } from "../../../../../../models/MockedSession/VismaOnlineMockedSession";
import CreateMockSessionForJwt from "./CreateMockSessionForJwt";
import CreateMockSessionForSevera from "./CreateMockSessionForSevera";
import CreateMockSessionForSpcsOnPrem from "./CreateMockSessionForSpcsOnPrem";
import CreateMockSessionForStandalone from "./CreateMockSessionForStandalone";
import CreateMockSessionForVismaNet from "./CreateMockSessionForVismaNet";
import CreateMockSessionForVismaOnline from "./CreateMockSessionForVismaOnline";

const CreateMockSessionFromIntegrationId = async (
  mockedSession:
    | VismaOnlineMockedSessionModel
    | SpcsOnPremMockedSessionModel
    | VismaNetMockedSessionModel
    | StandaloneMockedSessionModel
    | SeveraMockedSessionModel
    | JwtMockedSessionModel
    | undefined,
  integrationDetails: IntegrationDetailsModel | undefined,
  integrationId: string | undefined,
  setSuccessModal?: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (!mockedSession && integrationDetails && integrationId) {
    switch (true) {
      case integrationDetails.SessionProviderId.toLocaleLowerCase() ===
        SessionProviderMappingModel.VismaNet: {
        await CreateMockSessionForVismaNet(
          integrationDetails,
          integrationId,
          setSuccessModal
        );
        break;
      }
      case integrationDetails.SessionProviderId.toLocaleLowerCase() ===
        SessionProviderMappingModel.VismaOnline: {
        await CreateMockSessionForVismaOnline(
          integrationDetails,
          integrationId,
          setSuccessModal
        );
        break;
      }
      case integrationDetails.SessionProviderId.toLocaleLowerCase() ===
        SessionProviderMappingModel.Standalone: {
        await CreateMockSessionForStandalone(
          integrationDetails,
          integrationId,
          setSuccessModal
        );
        break;
      }
      case integrationDetails.SessionProviderId.toLocaleLowerCase() ===
      SessionProviderMappingModel.Jwt: {
      await CreateMockSessionForJwt(
        integrationDetails,
        integrationId,
        setSuccessModal
      );
      break;
    }
      case integrationDetails.SessionProviderId.toLocaleLowerCase() ===
        SessionProviderMappingModel.SPCSOnPrem: {
        await CreateMockSessionForSpcsOnPrem(
          integrationDetails,
          integrationId,
          setSuccessModal
        );
        break;
      }
      case integrationDetails.SessionProviderId.toLocaleLowerCase() ===
        SessionProviderMappingModel.Severa: {
        await CreateMockSessionForSevera(
          integrationDetails,
          integrationId,
          setSuccessModal
        );
        break;
      }
      default:
        break;
    }
  }
};

export default CreateMockSessionFromIntegrationId;
