import React, { useEffect, useState } from "react";
import { FormTemplateItems } from "../../../../../models/FormTemplate";
import { SpcsOnPremMockedSessionModel } from "../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { VismaOnlineMockedSessionModel } from "../../../../../models/MockedSession/VismaOnlineMockedSession";
import { UniqueIdGenerator } from "../../../../Shared/CommonFunctions";
import FormTemplate from "../../../../Shared/FormTemplate/FormTemplate";
import SetByIntegrationIdFormTemplate from "../../../../Shared/SetByIntegrationIdFormTemplate";
import CreateFormTemplateFromJson from "./Functions/CreateFormTemplateFromJson";
import FetchMockSession from "../../../../Shared/FetchMockSession";
import SaveMockSession from "./Functions/SaveMockSession";
import SetMockSessionFormat from "./Functions/SetMockSessionFormat";
import { VismaNetMockedSessionModel } from "../../../../../models/MockedSession/VismaNetMockedSession";
import { StandaloneMockedSessionModel } from "../../../../../models/MockedSession/StandaloneMockedSession";
import { SeveraMockedSessionModel } from "../../../../../models/MockedSession/SeveraMockedSession";
import Modal from "../../../../Shared/Modal/Modal";
import { JwtMockedSessionModel } from "../../../../../models/MockedSession/JwtMockedSession";

const UpdateMockSession = () => {
  const [
    byIntegrationIdFormData,
    setByIntegrationIdFormData,
  ] = useState<FormTemplateItems>(
    SetByIntegrationIdFormTemplate(
      "",
      "Get mock session for integrationId",
      "Get mock session"
    )
  );
  const [mockedSession, setMockedSession] = useState<
    | VismaOnlineMockedSessionModel
    | SpcsOnPremMockedSessionModel
    | VismaNetMockedSessionModel
    | StandaloneMockedSessionModel
    | SeveraMockedSessionModel
    | JwtMockedSessionModel
  >();
  const [mockSessionForm, setMockSessionForm] = useState<FormTemplateItems>();
  const [successModal, setSuccessModal] = useState<string>();

  useEffect(() => {
    if (mockedSession) {
      setMockSessionForm(
        CreateFormTemplateFromJson(`Mock session`, mockedSession)
      );
    }
  }, [mockedSession]);

  return (
    <div className="UpdateMockSession">
      <div className="panel border rounded">
        <div className="panel-body">
          <h1 className="no-margin-bottom text-center">Update Mock Session</h1>
        </div>
        <div className="panel-footer">
          {byIntegrationIdFormData.map((formData) => {
            return (
              <FormTemplate
                key={UniqueIdGenerator()}
                template={formData}
                handleSubmitEvent={(data) => {
                  setByIntegrationIdFormData(
                    SetByIntegrationIdFormTemplate(
                      data.IntegrationId,
                      "Get mock session for integrationId",
                      "Get mock session"
                    )
                  );
                  FetchMockSession(data, setMockedSession);
                }}
              />
            );
          })}
          {mockSessionForm &&
            mockSessionForm.map((formData) => {
              return (
                <FormTemplate
                  key={UniqueIdGenerator()}
                  template={formData}
                  handleSubmitEvent={(data) => {
                    setMockedSession(SetMockSessionFormat(data));
                    SaveMockSession(data, setSuccessModal);
                  }}
                />
              );
            })}
          {successModal && (
            <Modal
              message={successModal}
              title="Mock session was successfully updated."
              type="modal-success"
              toggleModal={(message: string) => setSuccessModal(message)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateMockSession;
