const LocalhostAuthenticatedUserContext = {
  clientPrincipal: {
    identityProvider: "google",
    userId: "testId1234",
    userDetails: "testUser@visma.com",
    userRoles: ["reader", "anonymous", "authenticated"],
  },
};

export default LocalhostAuthenticatedUserContext;
