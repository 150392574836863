import { httpRequest } from "../../../../../../hooks/useAxiosLoader";
import { JwtMockedSessionModel } from "../../../../../../models/MockedSession/JwtMockedSession";
import { SeveraMockedSessionModel } from "../../../../../../models/MockedSession/SeveraMockedSession";
import { SpcsOnPremMockedSessionModel } from "../../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { StandaloneMockedSessionModel } from "../../../../../../models/MockedSession/StandaloneMockedSession";
import { VismaNetMockedSessionModel } from "../../../../../../models/MockedSession/VismaNetMockedSession";
import { VismaOnlineMockedSessionModel } from "../../../../../../models/MockedSession/VismaOnlineMockedSession";
import SetMockSessionFormat from "./SetMockSessionFormat";

const SaveMockSession = async (
  mockSessionFromForm: {},
  setSuccessMessage: React.Dispatch<string | undefined>
) => {
  let mockSession = SetMockSessionFormat(mockSessionFromForm) as
    | VismaOnlineMockedSessionModel
    | SpcsOnPremMockedSessionModel
    | VismaNetMockedSessionModel
    | StandaloneMockedSessionModel
    | SeveraMockedSessionModel
    | JwtMockedSessionModel;
  const request = await httpRequest.put(`./api/UpdateMockSession`, {
    mockedSession: JSON.stringify(mockSession),
    mockedSessionId: mockSession.CurrentIntegration.IntegrationId,
    mockedSessionProvider: mockSession.SessionProviderId,
  });
  if (request.status === 200) {
    setSuccessMessage(
      `Mock session for integrationId ${mockSession.CurrentIntegration.IntegrationId} was updated.`
    );
  }
};

export default SaveMockSession;
