import React from "react";
import { FormTemplateItems } from "../../../../../../models/FormTemplate";
import { UniqueIdGenerator } from "../../../../../Shared/CommonFunctions";
import FormTemplate from "../../../../../Shared/FormTemplate/FormTemplate";

const ByParameters = () => {
  const byParametersFormData: FormTemplateItems = [
    {
      title: "Main Parameters",
      elements: [
        {
          type: "select",
          label: "Platform",
          // value: [],
        },
        {
          type: "select",
          label: "Product",
          // value: [],
        },
      ],
      submit: [],
    },
  ];
  const submitHandler = (data: any) => {
    console.log(data);
  };
  return (
    <div className="ByParameters">
      <div className="content-holder container margin-top">
        {byParametersFormData.map((formData) => {
          return (
            <FormTemplate
              key={UniqueIdGenerator()}
              template={formData}
              handleSubmitEvent={submitHandler}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ByParameters;
