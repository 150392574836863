import { SpcsOnPremMockedSessionModel } from "../../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { VismaOnlineMockedSessionModel } from "../../../../../../models/MockedSession/VismaOnlineMockedSession";

const SetPropertyAndValue = (
  property: string,
  mockSession: SpcsOnPremMockedSessionModel | VismaOnlineMockedSessionModel,
  mockSessionFromForm: {}
) => {
  let parentObjectName = property.split("-")[0];
  let propertyName = property.split("-")[1];
  if (!mockSession[parentObjectName]) {
    mockSession[parentObjectName] = {};
  }
  return (mockSession[parentObjectName][propertyName] =
    mockSessionFromForm[property]);
};

const SetMockSessionFormat = (mockSessionFromForm: {}) => {
  let mockSession = {} as
    | SpcsOnPremMockedSessionModel
    | VismaOnlineMockedSessionModel;
  for (const property in mockSessionFromForm) {
    switch (true) {
      case property.includes("CurrentIntegration-"): {
        SetPropertyAndValue(property, mockSession, mockSessionFromForm);
        break;
      }
      case property.includes("ParentApplication-"): {
        SetPropertyAndValue(property, mockSession, mockSessionFromForm);
        break;
      }
      case property.includes("UserIdentifiers-"): {
        SetPropertyAndValue(property, mockSession, mockSessionFromForm);
        break;
      }

      default: {
        mockSession[property] = mockSessionFromForm[property];
        break;
      }
    }
  }
  mockSession["Services"] = new Array(mockSession.CurrentIntegration);
  return mockSession;
};

export default SetMockSessionFormat;
