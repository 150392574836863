import React, { FunctionComponent, useState } from "react";
import { FormTemplateItems } from "../../../../../../models/FormTemplate";
import { UniqueIdGenerator } from "../../../../../Shared/CommonFunctions";
import FetchMockSession from "../../../../../Shared/FetchMockSession";
import FormTemplate from "../../../../../Shared/FormTemplate/FormTemplate";
import Modal from "../../../../../Shared/Modal/Modal";
import SetByIntegrationIdFormTemplate from "../../../../../Shared/SetByIntegrationIdFormTemplate";
import CreateMockSessionFromIntegrationId from "../../CreateMockSession/Functions/CreateMockSessionFromIntegrationId";
import GetHcUrl from "./Functions/GetHcUrl";
import SubmitByIntegrationIdHandler from "./Functions/SubmitByIntegrationIdHandler";
import { useNavigate } from "react-router-dom";

const ByIntegrationId: FunctionComponent = () => {
  const [byIntegrationIdFormData, setByIntegrationIdFormData] =
    useState<FormTemplateItems>(
      SetByIntegrationIdFormTemplate(
        "",
        "Get by IntegrationId",
        "Get integration details"
      )
    );
  const navigate = useNavigate();

  const [integrationParametersForm, setIntegrationParametersForm] =
    useState<FormTemplateItems | null>([]);

  const [successModal, setSuccessModal] = useState<string>();
  const [warningModel, setWarningModel] = useState<string>();

  const submitByMainParametersHandler = async (mainParametersFormData: {
    ApplicationCode: string;
    Country: string;
    Language: string;
    SessionProviderId: string;
    Source: string;
    SubmitButtonValue: string;
    Variant: string;
  }) => {
    const integrationId =
      byIntegrationIdFormData[0]?.elements[0]?.value?.toString() as string;
    let mockedSessionCreated: boolean = false;
    const response = await FetchMockSession(
      {
        IntegrationId: integrationId,
      },
      undefined,
      true,
      {
        ApplicationCode: mainParametersFormData.ApplicationCode,
        Source: mainParametersFormData.Source,
        LanguageCode: mainParametersFormData.Language,
        CountryCode: mainParametersFormData.Country,
        Variant: mainParametersFormData.Variant,
        SessionProviderId: mainParametersFormData.SessionProviderId,
      }
    );
    if (!response?.mockedSession) {
      await CreateMockSessionFromIntegrationId(
        response?.mockedSession,
        response?.integrationDetails,
        integrationId
      );
      mockedSessionCreated = true;
    }
    if (mainParametersFormData.SubmitButtonValue === "OpenNewWindow") {
      window.open(
        GetHcUrl(
          {
            ApplicationCode: mainParametersFormData.ApplicationCode,
            Source: mainParametersFormData.Source,
            LanguageCode: mainParametersFormData.Language,
            CountryCode: mainParametersFormData.Country,
            Variant: mainParametersFormData.Variant,
            SessionProviderId: mainParametersFormData.SessionProviderId,
          },
          integrationId
        ),
        "_blank"
      );
    } else {
      navigate(`/dev?integrationId=${integrationId}`, {
        state: {
          integrationDetails: mainParametersFormData,
          mockedSessionCreated,
        },
      });
    }
  };

  return (
    <div className="ByIntegrationId">
      <div className="content-holder container margin-top">
        {byIntegrationIdFormData.map((formData) => {
          return (
            <FormTemplate
              key={UniqueIdGenerator()}
              template={formData}
              handleSubmitEvent={SubmitByIntegrationIdHandler(
                setIntegrationParametersForm,
                setByIntegrationIdFormData,
                setWarningModel
              )}
            />
          );
        })}
        {integrationParametersForm &&
          integrationParametersForm?.map((formData) => {
            return (
              <FormTemplate
                key={UniqueIdGenerator()}
                template={formData}
                handleSubmitEvent={submitByMainParametersHandler}
              />
            );
          })}
        {successModal && (
          <Modal
            message={successModal}
            title="Mock session has been created."
            type="modal-success"
            toggleModal={(message: string) => setSuccessModal(message)}
          />
        )}
        {warningModel && (
          <Modal
            message={warningModel}
            title="Integration not found."
            type="modal-warning"
            toggleModal={(message: string) => setWarningModel(message)}
          />
        )}
      </div>
    </div>
  );
};

export default ByIntegrationId;
