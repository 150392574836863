import { httpRequest } from "../../../../../../hooks/useAxiosLoader";
import { IntegrationDetailsModel } from "../../../../../../models/IntegrationDetails";
import { CurrentIntegration } from "../../../../../../models/MockedSession/CurrentIntegration";
import { SpcsOnPremMockedSessionModel } from "../../../../../../models/MockedSession/SpcsOnPremMockedSession";
import { UniqueIdGenerator } from "../../../../../Shared/CommonFunctions";

const CreateMockSessionForSpcsOnPrem = async (
  integrationDetails: IntegrationDetailsModel,
  integrationId: string,
  setSuccessModal?: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  const currentIntegration: CurrentIntegration = {
    ApplicationCode: integrationDetails.ApplicationCode,
    PlatformName: integrationDetails.Source,
    CountryCode: integrationDetails.CountryCode,
    LanguageCode: integrationDetails.LanguageCode,
    DistributorName: "None",
    IntegrationId: integrationId,
    Variant: integrationDetails.Variant,
    OnlineApplicationId: UniqueIdGenerator(),
    ApplicationName: integrationDetails.ApplicationCode,
    HasTickets: 1,
    InvariantKey: "",
    ApplicationTypeName: "",
    CustomerNumber: 123,
  };
  let mockedSession: SpcsOnPremMockedSessionModel = {
    IsAuthorized: true,
    Services: [currentIntegration],
    IsAuthenticated: true,
    CurrentIntegration: currentIntegration,
    ParentApplication: "",
    Email: "mwoszczyk@vline.spcs.se",
    Firstname: "Mateus",
    Lastname: "W",
    SessionProviderId: integrationDetails.SessionProviderId.toLocaleLowerCase(),
    LocalUserIdentifier: UniqueIdGenerator(),
    MobileNumber: "",
    UserHasReadOnlyAccess: true,
    UserHasAccessToTickets: true,
    UserIdentifiers: {
      spcsAgreement: "true",
      spcsCustomerId: UniqueIdGenerator(),
      spcsUserId: "411c143d1be9cd069682f1fbff39bafc",
      origin:
        "https://localhost:4200/?application=administration&source=Standalone&userLanguageCode=sv&companyCountryCode=SE&preview=true&variant=2000&syntetic=true&agreement=true&customerNo=170717&userId=411c143d1be9cd069682f1fbff39bafc",
    },
    CustomerId: 123,
    HasAgreement: true,
  };
  const request = await httpRequest.post("./api/CreateMockSession", {
    mockedSession: JSON.stringify(mockedSession),
    mockedSessionId: integrationId,
    mockedSessionProvider: mockedSession.SessionProviderId.toLocaleLowerCase(),
  });
  if (request.status === 200 && setSuccessModal) {
    setSuccessModal(
      `Mock session for integrationId ${mockedSession.CurrentIntegration.IntegrationId} was created.`
    );
  }
};

export default CreateMockSessionForSpcsOnPrem;
