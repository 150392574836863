import { httpRequest } from "../../../../../../../hooks/useAxiosLoader";
import { FormTemplateItems } from "../../../../../../../models/FormTemplate";
import { IntegrationDetailsModel } from "../../../../../../../models/IntegrationDetails";
import SetByIntegrationIdFormTemplate from "../../../../../../Shared/SetByIntegrationIdFormTemplate";

const SubmitByIntegrationIdHandler = (
  setIntegrationMainParametersForm: React.Dispatch<
    React.SetStateAction<FormTemplateItems | null>
  >,
  setByIntegrationIdFormData: React.Dispatch<
    React.SetStateAction<FormTemplateItems>
  >,
  setWarningModel: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  return (data: { IntegrationId?: string }) => {
    setIntegrationMainParametersForm(null);
    if (data?.IntegrationId) {
      setByIntegrationIdFormData(
        SetByIntegrationIdFormTemplate(
          data.IntegrationId,
          "Get by IntegrationId",
          "Get integration details"
        )
      );

      httpRequest
        .get(`./api/GetIntegrationDetails?integrationId=${data.IntegrationId}`)
        .then(
          (response: { data?: IntegrationDetailsModel; status?: number }) => {
            if (response?.status === 204) {
              setWarningModel(
                `IntegrationId ${data.IntegrationId} was not found.`
              );
            }
            if (!!response.data) {
              setIntegrationMainParametersForm([
                {
                  title: "Main Parameters",
                  elements: [
                    {
                      type: "text",
                      label: "Source",
                      value: response.data?.Source,
                      readonly: true,
                    },
                    {
                      type: "text",
                      label: "ApplicationCode",
                      value: response.data?.ApplicationCode,
                      readonly: true,
                    },
                    {
                      type: "text",
                      label: "Language",
                      value: response.data?.LanguageCode,
                      readonly: true,
                    },
                    {
                      type: "text",
                      label: "Country",
                      value: response.data?.CountryCode,
                      readonly: true,
                    },
                    {
                      type: "text",
                      label: "Variant",
                      value: response.data?.Variant,
                      readonly: true,
                    },
                    // TODO: Delete this when implementing devTool
                    {
                      type: "text",
                      label: "SessionProviderId",
                      value: response.data?.SessionProviderId,
                      readonly: true,
                    },
                  ],
                  submit: [
                    {
                      name: "Open test page",
                      value: "OpenTestPage",
                      type: "submit",
                    },
                    {
                      name: "Open new window",
                      value: "OpenNewWindow",
                      type: "submit",
                    },
                  ],
                },
              ]);
            }
          }
        );
    }
  };
};

export default SubmitByIntegrationIdHandler;
