import GetIntegrationDetails from "./GetIntegrationDetails";

const GetState = (
  location: any,
  setState: React.Dispatch<
    React.SetStateAction<
      | {
          integrationDetails: {
            ApplicationCode: string;
            Country: string;
            Language: string;
            SessionProviderId: string;
            Source: string;
            SubmitButtonValue: string;
            Variant: string;
          };
          mockedSessionCreated: boolean;
        }
      | undefined
    >
  >,
  integrationId: string
) => {
  if (location.state) {
    setState(location?.state);
  } else {
    GetIntegrationDetails(integrationId, setState);
  }
};

export default GetState;
