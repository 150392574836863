import React, { useState } from "react";
import ByIntegrationId from "./ByIntegrationId/ByIntegrationId";
import ByParameters from "./ByParameters/ByParameters";
import "./DevPage.scss";

const DevPage = () => {
  const [state, setState] = useState({ tabIndex: 0 });

  function SwitchTab(value: number) {
    const currentTab = document.getElementById(`tabIndex${value}`);
    if (!currentTab?.classList.contains("disabled")) {
      const allTabs = document.querySelectorAll("li.nav-item");
      allTabs.forEach((tab) => tab.classList.remove("active"));
      currentTab?.classList.add("active");
      setState({ tabIndex: value });
    }
  }

  return (
    <div className="DevPage">
      <div className="panel border rounded">
        <div className="panel-body">
          <h1 className="no-margin-bottom text-center">Dev Page</h1>
        </div>
        <div className="panel-footer">
          <ul className="nav nav-tabs">
            <li
              className="nav-item active"
              role="presentation"
              onClick={() => SwitchTab(0)}
              id="tabIndex0">
              <a href="#/" role="tab" data-toggle="tab" aria-controls="normal">
                By IntegrationId
              </a>
            </li>
            <li
              className="nav-item disabled"
              role="presentation"
              onClick={() => SwitchTab(1)}
              id="tabIndex1">
              <a href="#/" role="tab" data-toggle="tab" aria-controls="normal">
                By Parameters
              </a>
            </li>
          </ul>
          <div id="myTabContent" className="tab-content border rounded">
            {state.tabIndex === 0 ? <ByIntegrationId /> : <ByParameters />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevPage;
