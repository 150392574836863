import React, { FormEvent } from "react";

const SetCshId = () => {
  return (
    <div className="SetCshId">
      <form
        className="form-horizontal col-md-8"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          window.supportPanelWidget?.open();
          window.supportPanelWidget?.setCshId(e.target[0].value);
        }}>
        <div className="form-group">
          <label htmlFor="cshId" className="col-sm-2 control-label text-center">
            Cshid:
          </label>
          <div className="col-sm-10">
            <input type="text" list="cshId" />
            <datalist id="cshId">
              <option value="common_dashboard">common_dashboard</option>
              <option value="version_highlights">version_highlights</option>
              <option value="release_notes">release_notes</option>
            </datalist>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-10 offset-sm-2">
            <button type="submit" className="btn btn-default right">
              SetCshId
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SetCshId;
