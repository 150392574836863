import { httpRequest } from "../../../../../../hooks/useAxiosLoader";
import { IntegrationDetailsModel } from "../../../../../../models/IntegrationDetails";

const GetIntegrationDetails = async (
  integrationId: string,
  setState: React.Dispatch<
    React.SetStateAction<
      | {
          integrationDetails: {
            ApplicationCode: string;
            Country: string;
            Language: string;
            SessionProviderId: string;
            Source: string;
            SubmitButtonValue: string;
            Variant: string;
          };
          mockedSessionCreated: boolean;
        }
      | undefined
    >
  >
) => {
  const integrationDetails: IntegrationDetailsModel = (
    await httpRequest.get(
      `./api/GetIntegrationDetails?integrationId=${integrationId}`
    )
  ).data;
  setState({
    integrationDetails: {
      ApplicationCode: integrationDetails.ApplicationCode,
      Country: integrationDetails.CountryCode,
      Language: integrationDetails.LanguageCode,
      SessionProviderId: integrationDetails.SessionProviderId,
      Source: integrationDetails.Source,
      SubmitButtonValue: "",
      Variant: integrationDetails.Variant,
    },
    mockedSessionCreated: false,
  });
};
export default GetIntegrationDetails;
