import React from "react";
import { FormTemplateModel } from "../../../models/FormTemplate";
import FormInput from "./FormInput/FormInput";
import FormSelect from "./FormSelect/FormSelect";
import FormSubmit from "./FormSubmit/FormSubmit";
import { SubmitHandler, useForm } from "react-hook-form";
import { UniqueIdGenerator } from "../CommonFunctions";
import FormCheckBox from "./FormCheckBox/FormCheckBox";
import FormComputed from "./FormComputed/FormComputed";
import { IntegrationDetailsModel } from "../../../models/IntegrationDetails";

const FormTemplate = (props: {
  template: FormTemplateModel;
  handleSubmitEvent: SubmitHandler<{ IntegrationId: string } | any>;
}) => {
  const { register, handleSubmit }: any = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  return (
    <div className="FormTemplate">
      <form className="row form-horizontal white-box">
        <fieldset className="col-sm-12">
          <legend className="no-margin-bottom">{props.template.title}</legend>
          <hr />
          {props.template.elements.map((element) => {
            return (
              <div
                className="form-group no-margin-bottom"
                key={UniqueIdGenerator()}>
                {element.type !== "computed" ? (
                  <label
                    htmlFor={element.label}
                    style={{
                      minWidth: "80px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}>
                    {element.label} :
                  </label>
                ) : (
                  <div className="col-sm-12 no-padding margin-top">
                    <h3>
                      {element.label} <hr />
                    </h3>
                  </div>
                )}
                <div className="col-sm-8 no-padding">
                  {(element.type === "text" || element.type === "number") && (
                    <FormInput
                      data={element}
                      register={register}
                      type={element.type}
                    />
                  )}
                  {element.type === "select" && (
                    <FormSelect data={element} register={register} />
                  )}
                  {element.type === "checkbox" && (
                    <FormCheckBox data={element} register={register} />
                  )}
                  {element.type === "computed" &&
                    element.computedElements &&
                    element.computedElements.map((computedElement) => {
                      return (
                        <FormComputed
                          computedElement={computedElement}
                          register={register}
                          key={UniqueIdGenerator()}
                          parentPropertyName={element.label}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
          {props.template.submit && (
            <FormSubmit
              data={props.template.submit}
              onClick={(submitButtonValue) => {
                handleSubmit((data: IntegrationDetailsModel) =>
                  props.handleSubmitEvent({
                    ...data,
                    SubmitButtonValue: submitButtonValue,
                  })
                )();
              }}
            />
          )}
        </fieldset>
      </form>
    </div>
  );
};

export default FormTemplate;
