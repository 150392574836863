const ToggleVerticalMenu = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  const pageHolder = document.querySelector("div.page-holder");
  const verticalNav = document.querySelector("div.vertical-nav");

  if (pageHolder && verticalNav) {
    if (!verticalNav?.classList.contains("hidden-menu")) {
      pageHolder.classList.add("hidden-vert-menu");
      verticalNav.classList.add("hidden-menu");
    } else {
      pageHolder.classList.remove("hidden-vert-menu");
      verticalNav.classList.remove("hidden-menu");
    }
  }
};

export default ToggleVerticalMenu;
