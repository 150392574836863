import { createBrowserHistory } from "history";

const DisplayMockedSessionCreatedModal = (
  location: any,
  setSuccessModal: React.Dispatch<React.SetStateAction<string | undefined>>,
  integrationId: string
) => {
  if (location?.state?.mockedSessionCreated) {
    const history = createBrowserHistory();
    const state = history.location.state as unknown & {
      mockedSessionCreated: boolean;
    };
    if (state.mockedSessionCreated) {
      setSuccessModal(
        `Mock session for integrationId ${integrationId} was created.`
      );
      history.replace(`?integrationId=${integrationId}`, {
        state: {
          ...(history.location.state as any),
          mockedSessionCreated: false,
        },
      });
    }
  }
};
export default DisplayMockedSessionCreatedModal;
