import React, { FormEvent } from "react";

const SetSize = () => {
  return (
    <div className="SetSize">
      <form
        className="form-horizontal col-md-8"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          window.supportPanelWidget?.open();
          window.supportPanelWidget?.setSize(
            e.target[0].value,
            e.target[1].value
          );
        }}>
        <div className="form-group">
          <label
            htmlFor="widthValue"
            className="col-sm-2 control-label text-center">
            Width:
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              name="width"
              className="form-control"
              id="widthValue"
              defaultValue="568"
            />
          </div>
        </div>
        <div className="form-group">
          <label
            htmlFor="heightValue"
            className="col-sm-2 control-label text-center">
            Height:
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              name="height"
              className="form-control"
              id="heightValue"
              defaultValue="688"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-10 offset-sm-2">
            <button type="submit" className="btn btn-default right">
              SetSize
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SetSize;
