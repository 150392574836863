import React from "react";
import DevPage from "../Path/DevPage/DevPage";
import CreateMockSession from "../Path/CreateMockSession/CreateMockSession";
import UpdateMockSession from "../Path/UpdateMockSession/UpdateMockSession";
import DeleteMockSession from "../Path/DeleteMockSession/DeleteMockSession";
import { Routes, Route } from "react-router-dom";
import DevTool from "../Path/DevTool/DevTool";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DevPage />}></Route>
        <Route
          path="/createMockSession"
          element={<CreateMockSession />}></Route>
        <Route
          path="/updateMockSession"
          element={<UpdateMockSession />}></Route>
        <Route
          path="/deleteMockSession"
          element={<DeleteMockSession />}></Route>
        <Route path="/dev" element={<DevTool />}></Route>
      </Routes>
    </>
  );
};

export default Routing;
