import React from "react";

const SetMaximized = () => {
  return (
    <div className="SetMaximized">
      <form className="form-horizontal col-md-8">
        <div className="form-group">
          <label className="col-sm-2 control-label text-center">
            Maximized:
          </label>
          <div className="col-sm-10">
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                window.supportPanelWidget?.open();
                window.supportPanelWidget?.setMaximized(true);
              }}>
              True
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                window.supportPanelWidget?.open();
                window.supportPanelWidget?.setMaximized(false);
              }}>
              False
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                window.supportPanelWidget?.open();
                window.supportPanelWidget?.setMaximized();
              }}>
              Toggle
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SetMaximized;
