import {
  HelpCentreWidgetOptions,
  HelpCentreWidgetSelectors,
} from "../../../../../../models/HelpCentreWidget";
import LoadWidgetScript from "./LoadWidgetScript";

const InitWidgetScript = async (
  state:
    | {
      integrationDetails: {
        ApplicationCode: string;
        Country: string;
        Language: string;
        SessionProviderId: string;
        Source: string;
        SubmitButtonValue: string;
        Variant: string;
      };
    }
    | undefined,
  integrationId: string
) => {
  if (state) {
    LoadWidgetScript();
    if (window.supportPanelWidgetInit) {
      const selectors: HelpCentreWidgetSelectors = {
        helpButton: "#btnOpen",
        notificationBadge: ".helpwidget-unread-panel-notifications",
      };
      let options: HelpCentreWidgetOptions = {
        applicationCode: state.integrationDetails.ApplicationCode,
        source: state.integrationDetails.Source,
        variant: state.integrationDetails.Variant,

        userLanguageCode: state.integrationDetails.Language,
        companyCountryCode: state.integrationDetails.Country,
      };
      options.mockedSessionId = integrationId;
      options.mockedSessionProvider = state.integrationDetails.SessionProviderId;
      const hcRef: Element | null = document.querySelector("#helpwidget-help");
      if (!hcRef) {
        window.supportPanelWidget = window.supportPanelWidgetInit(
          selectors,
          options
        );
      } else {
        window.supportPanelWidget?.reInit(options);
      }
    } else {
      setTimeout(() => {
        InitWidgetScript(state, integrationId);
      }, 500);
    }
  }
};

export default InitWidgetScript;
