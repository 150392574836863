import { AuthenticationContextModel } from "../../../../models/AuthenticationContext";

const LogOut = (
  event: React.MouseEvent<HTMLElement>,
  setUserContextHandler: (context: AuthenticationContextModel) => void
) => {
  event.preventDefault();
  if (window.location.hostname === "localhost") {
    setUserContextHandler({
      clientPrincipal: null,
    });
  }
};

export default LogOut;
