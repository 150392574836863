import { httpRequest } from "../../../../../../hooks/useAxiosLoader";
import isProd from "../../../../../Shared/GetHcEnv/IsProductionEnv";
import isStage from "../../../../../Shared/GetHcEnv/IsStageEnv";
import GetHcRootUrl from "../../../../../Shared/GetHcRootUrl";

const LoadWidgetScript = async () => {
  const widgetScript = document.querySelector("script#widgetScript");
  if (!widgetScript) {
    window["vismaSupportPanelRootUrl"] = GetHcRootUrl();
    let cdnAddress = "";

    switch (true) {
      case isProd:
        cdnAddress = "https://hc-cdn.visma.net";
        break;
      case isStage:
        cdnAddress = "https://hc-cdn.stag.visma.net";
        break;
      default:
        cdnAddress = "https://hc-cdn.acc.test.visma.net";
        break;
    }

    const assetsManifest = await (
      await httpRequest.get(`${cdnAddress}/sri/assets-manifest.json`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    )?.data;

    const scriptTag = document.createElement("script");
    scriptTag.src = `${cdnAddress}/widget.min.js`;
    scriptTag.integrity = assetsManifest["widget.min.js"].integrity;
    scriptTag.crossOrigin = "anonymous";
    scriptTag.id = "widgetScript";
    scriptTag.async = true;
    document.head.appendChild(scriptTag);
  }
};

export default LoadWidgetScript;
